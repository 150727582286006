import _ from 'lodash';
import { Observable } from 'lib0/observable'
import { helpers } from "@cargo/common";
import { FRONTEND_DATA } from "../globals";

class TouchChangeListener extends Observable {

	constructor() {
		super();

		this.pointerType = 'mouse';

		if(!helpers.isServer){

			window.addEventListener('pointerdown', this.onPointerMove);			
			window.addEventListener('pointermove', this.onPointerMove);

			if( FRONTEND_DATA.contentWindow?.CargoEditor && Object.keys(FRONTEND_DATA.contentWindow?.CargoEditor || {}).length > 0 ){
				this.bindFrontendEvents();
			} else {
				window.addEventListener('CargoEditor-load', this.bindFrontendEvents);
			}

		}
		
		this.triggerChange();

	}

	bindFrontendEvents = ()=>{
		window.removeEventListener('CargoEditor-load', this.bindFrontendEvents);
		FRONTEND_DATA.contentWindow.addEventListener('pointerdown', this.onPointerMove);			
		FRONTEND_DATA.contentWindow.addEventListener('pointermove', this.onPointerMove);		
	}

	onPointerMove = (e)=>{

		if( this.pointerType !== e.pointerType){
			this.pointerType = e.pointerType;
			this.triggerChange(e);
		}

	}

	triggerChange= (e)=>{
		this.emit('change',[this.pointerType, e]);
	}




}

export default new TouchChangeListener();