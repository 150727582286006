import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FRONTEND_DATA, PublishState } from "../globals";
import { actions } from "../actions";
import { paths } from "../router";

import { wsProvider } from "../lib/multi-user";

import UIWindowOpener from "./frontend-ui/ui-window-opener";
import ContextMenuOpener from "./frontend-ui/context-menu-opener"
import { WEB_HOSTNAME, HOMEPAGE_ORIGIN, helpers } from "@cargo/common";
import { ContextMenu, MenuContext } from "@cargo/common/context-menu";
import { ToolTip } from "@cargo/common/tooltip";

import { UIWindow, RemoteAlert, RemoteMessage } from "./ui-kit";
import { Alert, AlertContext, Button, Message, MessageContext, LoadingAnimation } from "@cargo/ui-kit";
import PreviewButton from "./preview-button";
import TopMenuBar from "./top-menu-bar";
import RightMenuBar from "./right-menu-bar";
import UIWindowLayer from "./ui-window-layer";
import { getDefaultEditingPID } from "../lib/page-list-manager";
import PageEditor from "./page-editor";
import { PublishingAnimation } from "./ui-kit";

import { HotKey } from "@cargo/ui-kit/hotkey/hotkey";
import { HotKeyManager } from "@cargo/ui-kit/hotkey/hotkey-manager";

import touchChangeListener from './touch-listener';

import _ from 'lodash';
import { store } from "../index";
import { toggleDarkMode } from '../darkmode';
import { globalUndoManager } from "../lib/undo-redo";
import { getShortCodeFromPasteEvent, executeShortcode } from "../lib/shortcode";
import * as Sentry from "@sentry/browser";
import { isLocalEnv, isServer, isMac, isPromoCodeTransitionPeriod } from "@cargo/common/helpers";

let proxiedModal;
let proxiedMenu;
let errorCount = 0;

class App extends Component {

	constructor(props) {

		super(props);

		this.setAdminRef = this.setAdminRef.bind(this);
		
		this.windowLayerRef = React.createRef();
		this.idlingOverlayRef = React.createRef();

		if(this.props.editorRole === 'Viewer') {
			// navigate to preview when the editor role is set to view only
			this.props.history.push(`/preview`);
		} else {
			// it's possible this fires before the site model containing the home page ID is loaded.
			// We still try, but also add a call to this in componentDidUpdate to make sure we always fire.
			if(this.props.hasSiteModel && this.props.hasScaffolding) {
				this.editHomePageOnFirstLoad();
			}
		}

		// fetch user data if needed
		if(_.isEmpty(this.props.user)) {
			this.props.fetchUser();
		}

		// some subscriptions data should be in user object, but until it is, we need to fetch it.
		this.props.fetchSubscriptions();

		// fetch site file assets
		this.props.fetchFiles();

		this.state = {
			publishing: 'inactive',
			showSiteUpToDateMessage: false,
			showPublishErrorMessage: false,
			admin_locked_spinner: false,
			pointerType: touchChangeListener.pointerType,
		}

		this.publishingAnimationStates = {
			inactive   : 'inactive',   // Nothing is in DOM
			confirmed  : 'confirmed',  // add animation container to DOM
			spinner    : 'spinner',    // fade in / show spinner
			transition : 'transition', // delete "Publishing" test from beneath spinner
			published  : 'published',  // swap to "Published" SVG
			complete   : 'complete',   // remove published SVG
		}

		this.animationReverse = false;

		this.isMac = isMac();

		HotKeyManager.setScopeOrder(['priority', 'contextmenu', 'customalert', 'alert', 'preview', 'window', 'interface', 'default']);


	}

	preloadChunks = () => {

		const chunks = [
			import('./right-menu-bar/publish-settings-window'),
			import('./right-menu-bar/page-settings-window-controller'),
			import('./right-menu-bar/text-styles-window'),
			import('./right-menu-bar/editors-window'),
			import('./right-menu-bar/media-window'),
			import('./top-menu-bar/page-list-window'),
			import('./right-menu-bar/css-editor-window')
		]

	}

	setFavicon = () => {

		if(!this.props.favicon_url) {
			return;
		}

		const favionTag = document.querySelector("link[rel~='icon']");

		if(favionTag) {
			favionTag.href = this.props.favicon_url;
		}

	}

	setWindowTitle = () => {

		const state = store.getState();

		let activePageOrSet = undefined;
		let title = "";

		if (
			this.props.activePID
			&& this.props.activePID !== state.site.homepage_id
			&& this.props.activePID !== state.site.mobile_homepage_id
			&& this.props.activePID !== 'root'
		) {
			if (state.pages.byId[this.props.activePID]) {
				activePageOrSet = state.pages.byId[this.props.activePID];
			} else if (state.sets.byId[this.props.activePID]) {
				activePageOrSet = state.sets.byId[this.props.activePID];
			}
		}

		if (this.props.website_title) {
			const pageTitle = (activePageOrSet?.title) ? `${activePageOrSet.title} — ` : '';
			title = `${pageTitle}${this.props.website_title}`;
		} 

		if(title !== document.title) {
			document.title = title;
		}

	}

	pauseUndoManager(event){
		globalUndoManager.pause();
	}

	resumeUndoManager(event){
		globalUndoManager.resume();
	}

	onAdminModeChange() {

		if(this.props.adminMode) {

			// Ensure we're always editing something
			this.editHomePageOnFirstLoad();

			// re-enable undo-red
			this.resumeUndoManager();

		} else {

			// no undo/redo when not actively editing the site
			this.pauseUndoManager();

		}

	}

	static getDerivedStateFromError(error) {

		console.error(error);

		// send caught error 
		if (errorCount === 0 && !isLocalEnv && !isServer) {
			Sentry.captureException(error);
		}

		// pause undo-redo
		globalUndoManager.pause();

		return { 
			hasError: true,
			error,
			errorCount: ++errorCount
		};

	}

	componentDidCatch(error, errorInfo) {
		
		// log this to some error logging service
		console.log('componentDidCatch', {
			error, errorInfo
		});

	}

	componentDidUpdate(prevProps, prevState) {
		// console.log("previous", prevProps.publishState )
		// console.log("current", this.props.publishState)

		// if( this.props.publishState === 0 ){
		// 	console.log(">> Publishing")
		// }
		// if( this.props.publishState === 1 ){
		// 	console.log(">> Published")
		// }
		// if( this.props.publishState === 2 ){
		// 	console.log(">> Draft")
		// }

		if(this.props.idling !== prevProps.idling) {
			
			if(this.idlingOverlayRef.current) {
				this.idlingOverlayRef.current.style.opacity = '0';
			}

			setTimeout(() => {
				if(this.idlingOverlayRef.current) {
					this.idlingOverlayRef.current.style.opacity = '1';
				}
			}, 100);

		}

		if(
			// editor role changed
			this.props.editorRole !== prevProps.editorRole
			// and it changed to view only
			&& this.props.editorRole === "Viewer"
		) {

			// navigate to preview when the editor role is set to view only
			this.props.history.push(`/preview`);

		}


		// set the app's title
		this.setWindowTitle();

		if(this.props.favicon_url !== prevProps.favicon_url) {
			this.setFavicon();
		}

		// set a flag in adminState that the publishing animation is active
		this.setAdminStatePublishingAnimationFlag(prevProps, prevState);

		if(
			this.props.hasSiteModel 
			&& this.props.hasScaffolding 
			&& (prevProps.hasSiteModel === false || prevProps.hasScaffolding === false)
		) {
			// when we first loaded both the site model and scaffolding we're ready to edit the default page.
			this.editHomePageOnFirstLoad();
		}

		if(this.props.adminMode !== prevProps.adminMode) {
			this.onAdminModeChange();
		}

		// user list updates
		if( this.props.usersUpdatedAt !== undefined && this.props.usersUpdatedAt !== prevProps.usersUpdatedAt) {
			//  Fetch site model from the server
			this.props.fetchSiteModel();
		}

		// site model updates
		if( this.props.siteUpdatedAt !== undefined && this.props.siteUpdatedAt !== prevProps.siteUpdatedAt ) {
			//  Fetch site model from the server
			this.props.fetchSiteModel();
		}

		// site design model updates
		if( this.props.siteDesignUpdatedAt !== undefined && this.props.siteDesignUpdatedAt !== prevProps.siteDesignUpdatedAt ) {
			//  Fetch site design model from the server
			this.props.fetchSiteDesignModel();
		}

		// custom font update
		if( this.props.fontCollectionUpdatedAt !== undefined 
			&& this.props.fontCollectionUpdatedAt !== prevProps.fontCollectionUpdatedAt
			&& this.props.hasFontCollection
		) {
			//  Fetch site model from the server
			this.props.fetchCustomFontCollection();
		}

		// Re-pull subscriptions list when domain is connected
		if( this.props.domain_pending !== prevProps.domain_pending 
			&& prevProps.domain_pending === false
			&& this.props.domain_pending === true 
		) {
			// Subscriptions pull is hack / temporary
			// subscriptions data should be in user object, but until it is, we need to fetch it.
			this.props.fetchSubscriptions();
		}

		// Domain connected redirect
		if(    this.props.domain !== null && this.props.domain !== ''
			&& this.props.domain_active !== prevProps.domain_active
			&& this.props.domain_active === true
		){

			// prevent undo-redo while navigating away
			globalUndoManager.pause();

			const domainConnectedRedirect = () => {
				setTimeout(() => {
					// get path name
					const currentPath = window.location.pathname;
					// redirect to domain + path
					window.location.href = window.location.protocol + '//' + this.props.domain + currentPath;
				}, 2000);
			}

			if(document.hidden) {
				// wait till the tab is not in the background
				document.addEventListener('visibilitychange', domainConnectedRedirect);
			} else {
				// immediately start the redirect timer
				domainConnectedRedirect();
			}

			this.urlChanged = true;
			// render notification
			this.setState({
				site_url_changed: true
			});
		}

		// Domain disconnect
		if(    this.props.domain_active === false
			&& this.props.domain_active !== prevProps.domain_active
			&& ( !this.props.domain || this.props.domain === '' )
		){

			// prevent undo-redo while navigating away
			globalUndoManager.pause();

			const domainDisconnectRedirect = () => {
				setTimeout(() => {
					// get path name
					const currentPath = window.location.pathname;
					// redirect to cargo url + path
					window.location.href = window.location.protocol + '//' + `${this.props.site_url}.${WEB_HOSTNAME}` + currentPath;

				}, 2000);
			}

			if(document.hidden) {
				// wait till the tab is not in the background
				document.addEventListener('visibilitychange', domainDisconnectRedirect);
			} else {
				// immediately start the redirect timer
				domainDisconnectRedirect();
			}

			this.urlChanged = true;
			// render notification
			this.setState({
				site_url_changed: true
			});
		}

		// site url changed
		if( prevProps.site_url && this.props.site_url !== prevProps.site_url && prevProps.domain_active === false && this.props.domain_active === false ) {
			
			// prevent undo-redo while navigating away
			globalUndoManager.pause();

			const redirect = () => {
				setTimeout(() => {

					// grab the current host, split it's parts
					const currentHostParts = window.location.host.split('.');
					// update the subdomain
					currentHostParts[0] = this.props.site_url;
					// redirect
					window.location.href = window.location.protocol + '//' + currentHostParts.join('.') + window.location.pathname;

				}, 2000);
			}
			// Don't do anything if you've got a domain up.
			// Changing your Cargo URL won't do much
			if(document.hidden) {
				// wait till the tab is not in the background
				document.addEventListener('visibilitychange', redirect);
			} else {
				// immediately start the redirect timer
				redirect();
			}

			// render notification
			this.setState({
				site_url_changed: true
			});

		}

		// ydoc being discarded
		if(
			(
				prevProps.publishState !== PublishState.Discarding
				&& this.props.publishState === PublishState.Discarding
			)
			// handle loading the site whilst discarding
			|| (
				this.props.publishState === PublishState.Discarding
				&& this.state.discarding !== true
			)
		) {

			// wipe undo history
			globalUndoManager.clear();

			// prevent new undo/redo items
			globalUndoManager.pause();

			// lock admin
			this.setState({
				discarding: true
			});
		}

		// publishedAt timestamp was updated
		if(prevProps.publishedAt !== this.props.publishedAt) {

			// For extra security also wipe undo/redo stack here. This 
			// because we can't 100% rely on PublishState changes
			// to fire in the right sequence
			globalUndoManager.clear();
		
		}

		if(
			prevProps.publishState !== PublishState.Discarded
			&& this.props.publishState === PublishState.Discarded
		) {

			this.setState({
				discarding_complete: true
			});

			// We are discarded. Reload the admin
			const reload = () => {
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			}

			if(document.hidden) {
				// wait till the tab is not in the background
				document.addEventListener('visibilitychange', reload);
			} else {
				// immediately start the reload timer
				reload();
			}

		}

		// start publishing animation from CRDT information
		// Show publishing animation wether we've made an edit or not since last publish.
		if(
			(
				prevProps.publishState !== PublishState.Publishing
				&& this.props.publishState === PublishState.Publishing
			)
			// handle loading the site whilst publishing
			|| (
				this.props.publishState === PublishState.Publishing
				&& this.state.publishing === 'inactive'
			)
		){

			// prevent new undo/redo items during publish
			globalUndoManager.pause();

			// wipe undo history
			globalUndoManager.clear();

			// Publishing animation direction
			let random = Math.random();
			this.animationReverse = random < 0.5; 

			// from a window
			if( this.state.publishing === 'inactive' ){
				// This scenario occurs when publishing from the window
				this.setState({ publishing: this.publishingAnimationStates.confirmed }, ()=> {
					this.setState({ publishing: this.publishingAnimationStates.spinner })
				})
			}

			//from alert
			if( this.state.publishing === 'confirmed' ){
				// Modal sets state to confirm for us, so all we need to do is hit the spinner
				this.setState({ publishing: this.publishingAnimationStates.spinner })
			}

		}

		// Publishing is functionally complete, start hiding the animation component
		// We can't rely on "past states" here since the app can do a lot from differnet user perspectives
		// But we do know if publishing is complete NOW and we're showing a spinner, we gotta get rid of it.
		if(    this.state.publishing === 'spinner' 
			&& this.props.publishState === PublishState.Published
		){
			this.handlePublishingSuccess();
		}

		// Publishing has errored for some reason... 
		if(    this.state.publishing === 'spinner'
			&& this.props.publishState === PublishState.Error
		){

			window.requestAnimationFrame(()=> {
				this.handlePublishingFailure();
			});

		}

		// Lock admin when inserting content via a shortcode, or during any other async event where we might want to pause
		// all editing.
		if( this.props.adminLockedSpinner !== prevProps.adminLockedSpinner ){

			if(this.props.adminLockedSpinner === true) {
				// pause undo-redo while admin is locked
				globalUndoManager.pause();
			} else {
				globalUndoManager.resume();
			}

			this.setState({ admin_locked_spinner: this.props.adminLockedSpinner })
		}

		if( this.props.CRDTSynced !== prevProps.CRDTSynced ){

			if(this.props.CRDTSynced !== true) {
				// pause undo-redo while CRDT doc is not synced
				globalUndoManager.pause();
			} else {
				globalUndoManager.resume();
			}

		}

		if(    this.props.hasSiteModel
			&& this.props.expirationDate
			&& this.props.promoCodeTransition === null
			&& this.props.is_upgraded === true
		){
			let isPromoCodeTransitionPeriod = helpers.isPromoCodeTransitionPeriod( this.props.expirationDate );
			this.props.updateAdminState({'promoCodeTransition': isPromoCodeTransitionPeriod})
		}

		if( this.props.expirationDate !== prevProps.expirationDate 
			&& this.props.promoCodeTransition === true
		){
			let isPromoStillCodeTransitionPeriod = helpers.isPromoCodeTransitionPeriod( this.props.expirationDate );
			this.props.updateAdminState({'promoCodeTransition': isPromoStillCodeTransitionPeriod})
		}

	}

	setAdminStatePublishingAnimationFlag = (prevProps, prevState) => {
		if (prevState.publishing !== 'inactive' && this.state.publishing === 'inactive') {
			this.props.updateAdminState({'publishingAnimationActive': false})
		}

		if (prevState.publishing === 'inactive' && this.state.publishing !== 'inactive') {
			this.props.updateAdminState({'publishingAnimationActive': true})
		}

		if( prevProps.publishState !== PublishState.SavingAs && this.props.publishState === PublishState.SavingAs ){
			this.props.updateAdminState({'publishingAnimationActive': true})
		}

		if( prevProps.publishState === PublishState.SavingAs && this.props.publishState !== PublishState.SavingAs ){
			this.props.updateAdminState({'publishingAnimationActive': false})
		}
		
	}

	editHomePageOnFirstLoad() {

		if(
			this.props.location.pathname === "/" 
			&& this.props.hasSiteModel
			&& this.props.hasScaffolding
		) {

			getDefaultEditingPID().then(pidToEdit => {

				if(pidToEdit !== undefined) {
					this.props.history.replace(`/${pidToEdit}`);
				}

			});
			
		}

	}

	delayPreviewForCommerceSave() {
		if( this.props.shopId && this.props.commerceWindowOpen ){

			if( window.Cargo?.Ecommerce?.View?.currentView?.saveButton?.saveEnabled === true || window.Cargo?.Ecommerce?.View?.currentView?.SaveButton?.saveEnabled === true ){
				// If there are unsaved commerce changes, we need to prompt the user to save before closing the window.
				// This event sends the "open ui window" info to the c2-c3 view controller, which will open the window after
				// the save modal is cleared.
				window.dispatchEvent(
					new CustomEvent('await-c3-ui-window-open', {
						detail: { uiWindow: null, options: 'preview' }
					})
				);

				return true
			}
		}

		return false
	}

	showPublishingComplete() {
		// STEP 2: REMOVE SPINNER / SHOW "Publish Complete"
		setTimeout(()=>{
		window.requestAnimationFrame(()=> {
		this.setState({ publishing: this.publishingAnimationStates.published }, ()=>{

			// STEP 3: Hide "Publish Complete" after check animation is complete
			setTimeout(()=>{
			window.requestAnimationFrame(()=> {
			this.setState({ publishing: this.publishingAnimationStates.complete }, ()=>{

				// STEP 4: Remove everything from DOM after transition is over. 
				setTimeout(()=>{ 
				window.requestAnimationFrame(()=> {
					this.setState({ publishing: this.publishingAnimationStates.inactive });

					// Emit event for publish complete. This lets us forward through to the published site
					// when publish is hit in the context menu "Publish and go to site".
					let lastPublisher = this.props.lastPublishedBy;

					window.dispatchEvent(
						new CustomEvent('c3-publish-complete', {
							detail: { lastPublishedBy: lastPublisher }
						})
					);

				})       // STEP 4 request frame
				}, 250); // STEP 4 Set Timeout

			});      // STEP 3 setState
			}); 	 // STEP 3 request frame
			}, 1000);// STEP 3 Set Timeout

		});     // STEP 2 setState
		}); 	// STEP 2 request frame
		}, 400);// STEP 2 set timeout
	}

	// Removes publishing spinner and resets state.
	handlePublishingSuccess(){

		// resume undo-redo
		globalUndoManager.resume();

		// wipe anything that might've been captured during publish
		globalUndoManager.clear();

		// STEP 1: Remove "Publishing" text and wait a moment.
		setTimeout(()=>{
			window.requestAnimationFrame(()=> {
				this.setState({ publishing: this.publishingAnimationStates.transition }, ()=>{

					this.showPublishingComplete();


				});     // STEP 1 setState
			});		// STEP 1 request frame
		}, 500);// STEP 1 Set Timeout

	}

	handlePublishingFailure(){

		// resume undo-redo
		globalUndoManager.resume();

		// wipe anything that might've been captured during publish
		globalUndoManager.clear();

		Sentry.captureMessage("Publishing resulted in error");

		setTimeout(()=>{ 
			this.setState({publishing: this.publishingAnimationStates.inactive}, () => {
				this.setState({'showPublishErrorMessage': true})
			})
		}, 250);

	}

	setAdminRef(node) {
		this.adminRef = node;
	}

	AdminWrapper = (props) => {

		if(this.props.isPreviewing) {
			return <>
				{/* Print a style tag to hide the admin bars */}
				<style>{`
					#device-viewport {
						--admin-margin: 0px;
					}
				`}</style>
				{props.children}
			</>

		}

		return <div 
			id="admin"
			browser-name={helpers.isSafari() ? 'safari': helpers.isChrome() ? 'chrome' : null}
			viewport-name={this.props.currentViewport}
			ref={ this.setAdminRef }
		>
			<TopMenuBar/>
			<RightMenuBar/>
			{ props.hideUIWindows ? null : <UIWindowLayer 
				windowLayerRef={this.windowLayerRef}
				globalClickListener={this.globalClickListener}
			/> }

			{props.children}
		</div>
	}

	PublishDialog = (props) => {

		return <>

			<MessageContext.Consumer>
				{(Message) => (
					<HotKey 
					   shortcut="cmd+s"
					   config={{ keyCode: 83, metaKey: true }}
					   callback={()=> {
							Message.showMessage({
								duration: 4000,
								messageText: `<div className="message">Changes are auto-saved.<br/> To publish to live site, type <i>cmd+p</i></div>`
							});
					   }}
					   scope="default"
					 />
				)}
			</MessageContext.Consumer>

			<HotKey 
			   shortcut="cmd+p"
			   config={{ keyCode: 80, metaKey: true, shiftKey: false, altKey: false, macCtrlKey: false}}
			   callback={(e) => {
					if( this.props.publishingAnimationActive ){ return }

					if ( this.props.publishState !== PublishState.Published && this.props.publishState !== PublishState.Discarded ) {
						wsProvider.sendPublishRequest();
					} else {
						this.setState({'showSiteUpToDateMessage': true})
					}
				}}
			   scope="default"
			 />

			{this.state.showSiteUpToDateMessage && 
				<MessageContext.Consumer>
					{(Message) => {
						if( this.props.publishingAnimationActive ){ return }
						setTimeout(() => {
							Message.showMessage({
								messageText: 'Site is up to date',
								duration: 4000,
							})
							this.setState({'showSiteUpToDateMessage': false})
						});

					}}
				</MessageContext.Consumer>
			}

			{this.state.showPublishErrorMessage && 
				<MessageContext.Consumer>
					{(Message) => {
						if( this.props.publishingAnimationActive ){ return }
						setTimeout(() => {
							Message.showMessage({
								messageText: `Publish Error — please <span class="intercom-link">contact support</span>`,
								pointerEvents: true,
								onClick: e => {
									if(e?.target?.getAttribute('class') === "intercom-link") {
										Intercom('showNewMessage');
									}
								}
							})
							this.setState({'showPublishErrorMessage': false})
						});

					}}
				</MessageContext.Consumer>
			}

		</>

	}

	render() {

		// Lock the admin and show a message when an error has occurred
		if (this.state.hasError) {

			// Safe mode in case rendering the admin in the background results in repeated
			if(this.state.errorCount > 3) {

				debugger;
				
				window.alert('Something went wrong, please reload the page.');
				window.location.reload();

				return null;
			}

			return <this.AdminWrapper>
				<Message>
					<MessageContext.Consumer>
						{(Message) => {
							setTimeout(() => {
								Message.showMessage({
									messageText: 'Something went wrong, please reload the page.',
									preventClickout: true
								});
							});
						}}
					</MessageContext.Consumer>
				</Message>
			</this.AdminWrapper>

		}

		if( this.props.auth.authenticated !== true ) {
			window.location.reload();
		}



		if(this.props.idling) {

			// We're idling after a certain amount of no input
			return <this.AdminWrapper hideUIWindows={true}>
				<div ref={this.idlingOverlayRef} className="idle-overlay"></div>
				<Message>
					<MessageContext.Consumer>
						{(Message) => {

							setTimeout(() => {
								Message.showMessage({
									messageText: 'Paused' ,
									preventClickout: true
								});
							}, 1000);

						}}
					</MessageContext.Consumer>
				</Message>
			</this.AdminWrapper>

		} else if (this.state.pointerType == 'touch'){
			// display an overlay if a touch device is being used
			return <this.AdminWrapper hideUIWindows={true}>
				<div className="touch-stop-overlay"></div>
				<Message>
					<MessageContext.Consumer>
						{(Message) => {

							setTimeout(() => {
								Message.showMessage({
									messageText: 'Use a mouse or touchpad to continue' ,
									preventClickout: true
								});
							}, 0);

						}}
					</MessageContext.Consumer>
				</Message>
			</this.AdminWrapper>			
		}

		if(this.props.CRDTSynced === false) {

			// CRDT is not synced. Lock the admin till it is synced again
			return <this.AdminWrapper hideUIWindows={true}>
				<Message>
					<MessageContext.Consumer>
						{(Message) => {

							setTimeout(() => {
								Message.showMessage({
									messageText: 'Reconnecting…' ,
									preventClickout: true
								});
							});

						}}
					</MessageContext.Consumer>
				</Message>
			</this.AdminWrapper>

		}

		if(this.state.discarding || this.state.discarding_complete) {

			// the site URL has changed. Lock the admin, show the notification and redirect
			return <this.AdminWrapper>
				<Message>
					<MessageContext.Consumer>
						{(Message) => {

							setTimeout(() => {
								Message.showMessage({
									messageText: this.state.discarding_complete !== true ? 'Discarding…' : 'Discarding Complete',
									type: 'loading',
									preventClickout: true
								});
							});

						}}
					</MessageContext.Consumer>
				</Message>
			</this.AdminWrapper>

		}

		if(this.props.publishState === PublishState.SavingAs) {

			// the site URL has changed. Lock the admin, show the notification and redirect
			return <this.AdminWrapper>
				<Message>
					<MessageContext.Consumer>
						{(Message) => {

							setTimeout(() => {
								Message.showMessage({
									messageText: 'Saving as new site…',
									type: 'loading',
									preventClickout: true
								});
							});

						}}
					</MessageContext.Consumer>
				</Message>
			</this.AdminWrapper>

		}

		if(this.state.site_url_changed) {

			// the site URL has changed. Lock the admin, show the notification and redirect
			return <this.AdminWrapper>
				<Message>
					<MessageContext.Consumer>
						{(Message) => {

							setTimeout(() => {
								Message.showMessage({
									messageText: 'URL updated — site will now reload…',
									preventClickout: true,
									className:'url-updated'
								});
							});

						}}
					</MessageContext.Consumer>
				</Message>
			</this.AdminWrapper>

		}

		if( this.state.admin_locked_spinner ) {

			return <this.AdminWrapper>
				<Message>
					<MessageContext.Consumer>
						{(Message) => {

							setTimeout(() => {
								Message.showMessage({
									messageText: ' ',
									type: 'loading',
									preventClickout: true
								});
							});

						}}
					</MessageContext.Consumer>
				</Message>
			</this.AdminWrapper>

		}

		if(this.props.isPreviewing) {
			return (
				<Alert>
				<Message>
					<RemoteMessage />
					{ this.props.previewMessage && this.props.previewMessage !== null ? (
						<MessageContext.Consumer>
							{(Message) => {

								setTimeout(() => {
									Message.showMessage({
										messageText: this.props.previewMessage,
										type: 'preview',
										duration: 1500,
										preventClickout: false
									});
								});

							}}
						</MessageContext.Consumer>
					) : ( null )}

					<PreviewButton previewing={true} allowAdminAccess={this.props.editorRole !== "Viewer"} />

					<HotKey 
						shortcut="cmd+shift+x"
						config={{ keyCode: 88, metaKey: true, shiftKey: true, altKey: false, macCtrlKey: false}}
						callback={()=> {
							this.props.updateAdminState({'viewport': this.props.viewport === 'mobile' ? 'desktop' : 'mobile'});
						}}
						scope="default"
					/>

					<HotKey 
						shortcut="cmd+shift+1"
						config={{ keyCode: 49, metaKey: true, shiftKey: true, altKey: false, macCtrlKey: false}}
						callback={()=> {
							window.location.href = HOMEPAGE_ORIGIN;
						}}
						scope="default"
					/>

					<HotKey 
						shortcut="cmd+shift+2"
						config={{ keyCode: 50, metaKey: true, shiftKey: true, altKey: false, macCtrlKey: false}}
						callback={()=> {
							window.location.href = HOMEPAGE_ORIGIN+'/templates';
						}}
						scope="default"
					/>

					{this.PublishDialog()}

					{/* Publishing Animation */}
					<PublishingAnimation publishing={this.state.publishing} reverse={this.animationReverse} />
				</Message>
				</Alert>
			)
		}

		if(this.firstRender !== true) {
			this.firstRender = true;

			// Only redirect on first render if necessary
			// This allows domain changes delay temporarily
			// and alert user with proper messaging
			if(
				!helpers.isServer
				// This site has an active domain
				&& this.props.domain_active === true
				// but we are currently not loading the site through that domain 
				&& this.props.domain !== window.location.host
				// and we're not showing a message
			) {
				window.location.hostname = this.props.domain + '/edit';
			}
		}

		return (
			<Alert>
				<AlertContext.Consumer>
					{(Alert) => (
						<RemoteAlert alert={Alert} />
					)}
				</AlertContext.Consumer>

				{/* This needs to be placed here so it fires before we close any UI windows in the esc listener there */}
				<HotKey 
					shortcut="esc"
					config={{ keyCode: 27 }}
					callback={() => {

						const state = store.getState();

						// no ui windows were open when esc was pressed
						if(_.keys(state.uiWindows.byId).length === 0) {

							// navigate home on esc
							getDefaultEditingPID().then(pidToEdit => {
								if(pidToEdit !== undefined && pidToEdit !== this.props.PIDBeingEdited) {
									this.props.history.replace(`/${pidToEdit}`);
								}
							});

						}

					}}
					scope="default"
				/>

				<PageEditor>
					<Message>
						<RemoteMessage />
					<ToolTip>
					<ContextMenu>
					<UIWindowOpener/>
					<ContextMenuOpener/>

						<this.AdminWrapper>
							<PublishingAnimation publishing={this.state.publishing} reverse={this.animationReverse} />
						</this.AdminWrapper>

						{/* Shortcut to preview */}
						{/* <HotKey 
							shortcut="cmd+backslash"
							config={{ keyCode: 220, metaKey: true }}
							callback={()=> {
								if( this.delayPreviewForCommerceSave() ){ return }

								this.props.history.push('/preview')
							}}
							scope="default"
						/> */}

						<HotKey 
							shortcut="cmd+esc"
							config={{ keyCode: 27, metaKey: true }}
							callback={()=> {
								if( this.delayPreviewForCommerceSave() ){ return }

								this.props.history.push('/preview')
							}}
							scope="default"
						/>

						<HotKey 
							shortcut="cmd+shift+1"
							config={{ keyCode: 49, metaKey: true, shiftKey: true, altKey: false, macCtrlKey: false}}
							callback={()=> {
								window.location.href = HOMEPAGE_ORIGIN;
							}}
							scope="default"
						/>

						<HotKey 
							shortcut="cmd+shift+2"
							config={{ keyCode: 50, metaKey: true, shiftKey: true, altKey: false, macCtrlKey: false}}
							callback={()=> {
								window.location.href = HOMEPAGE_ORIGIN+'/templates';
							}}
							scope="default"
						/>

						<HotKey 
							shortcut="ctrl+shift+i"
							config={{ keyCode: 73, metaKey: false, shiftKey: true, altKey: false, macCtrlKey: true}}
							callback={()=> {
								toggleDarkMode()
							}}
							scope="default"
						/>

						{/* Shortcut Alert to Publish */}
						{this.PublishDialog()}
					</ContextMenu>
					</ToolTip>
					</Message>
				</PageEditor>
			</Alert>
		);

	}

	onReceiveCustomError = (e)=>{

		if( !e.detail ){
			return
		}

		Sentry.captureMessage(e.detail.message, {
			extra: e.detail.extra
		});		
	}

	componentDidMount() {

		if(!helpers.isServer) {

			touchChangeListener.on('change', this.onPointerTypeChange);

			window.addEventListener("keydown", this.handleGlobalKeyDown);
			FRONTEND_DATA.contentWindow.addEventListener("keydown", this.handleGlobalKeyDown);

			window.addEventListener("paste", this.handleGlobalPasteInCapturingPhase, {capture: true});
			FRONTEND_DATA.contentWindow.addEventListener("paste", this.handleGlobalPasteInCapturingPhase, {capture: true});
			
			if( !this.props.hasFontCollection ) {
				this.props.fetchFontCollection( true ).then((res) => {
					if( res.status === 200 ){
						this.props.fetchCustomFontCollection( res.data )
					} else {
						console.warn('Error loading Cargo fonts, custom fonts request will not be made.');
						// Attempt re-fetch and process.
						this.props.fetchFontCollection();
					}
				})
			}

			if(!this.props.hasAdminList) {
				this.props.fetchAdminList();
			}

			// Pauses undo redo when alert modal opens, starts it again when it closes.
			window.addEventListener('openAlertModal',  this.pauseUndoManager, false);
			window.addEventListener('closeAlertModal', this.resumeUndoManager, false);

			window.addEventListener('custom-error-report', this.onReceiveCustomError, false);
		}

		if(!this.props.adminMode) {
			this.onAdminModeChange();
		}

		this.setFavicon();

		// content frame is hidden until the admin loads
		document.querySelector('#client-frame').style.display = '';

		// Start loading remainder of chunks after 250ms
		setTimeout(() => {
			this.preloadChunks();
		}, 1000);

	}

	componentWillUnmount() {
		if(!helpers.isServer) {
			touchChangeListener.off('change', this.onPointerTypeChange);
			window.removeEventListener('custom-error-report', this.onReceiveCustomError, false);			
			window.removeEventListener("keydown", this.handleGlobalKeyDown);
			FRONTEND_DATA.contentWindow.removeEventListener("keydown", this.handleGlobalKeyDown);

			window.removeEventListener("paste", this.handleGlobalPasteInCapturingPhase);
			FRONTEND_DATA.contentWindow.removeEventListener("paste", this.handleGlobalPasteInCapturingPhase);

			window.removeEventListener('openAlertModal',  this.pauseUndoManager, false);
			window.removeEventListener('closeAlertModal', this.resumeUndoManager, false);
		}
	}

	onPointerTypeChange = (pointerType, event)=>{
		// cancel event while overlay goes up
		if( pointerType =='touch' && event){
			event.preventDefault();
			event.stopPropagation();
		}

		this.setState({pointerType})
	}

	handleGlobalKeyDown = event => {

		if( ( this.isMac && event.metaKey ) || ( !this.isMac && event.ctrlKey ) ) {
			// check if the event is default prevented. If so, don't do a global undo
			if(event.defaultPrevented === false && event.key === "z") {
				if(event.shiftKey) {
					event.preventDefault();
					globalUndoManager.redo();
				} else {
					event.preventDefault();
					globalUndoManager.undo();
				}
			}
		}

	}

	handleGlobalPasteInCapturingPhase = event => {

		const shortcode = getShortCodeFromPasteEvent(event);

		if(shortcode) {
			
			// cancel the paste
			event.stopPropagation();
			event.preventDefault();

			// execute shortcode
			executeShortcode(shortcode).then(() => {
				console.log('done!')
			}).catch(e => {
				console.error(e);
			})

		}

	}

}

function mapReduxStateToProps(state, ownProps) {

	let editorRole;

	if(state.auth.data?.id) {
		editorRole = _.find(state.site?.editors, editor => editor.id === parseInt(state.auth.data.id))?.role
	}

	return {
		adminMode: state.frontendState.adminMode,
		PIDBeingEdited: state.frontendState.PIDBeingEdited,
		activePID: state.frontendState.activePID,
		hasSiteModel: state.frontendState.hasSiteModel,
		hasScaffolding: state.frontendState.hasScaffolding,
		matchedRoute: state.adminState.matchedRoute?.path,
		siteUpdatedAt: state.adminState.crdt.siteUpdatedAt,
		fontCollectionUpdatedAt: state.adminState.crdt.fontCollectionUpdatedAt,
		usersUpdatedAt: state.adminState.crdt.usersUpdatedAt,
		siteDesignUpdatedAt: state.adminState.crdt.siteDesignUpdatedAt,
		lastPublishedBy: state.adminState.crdt?.publishedBy,
		isPreviewing: state.adminState.matchedRoute?.path === paths.PREVIEW || editorRole === "Viewer",
		previewMessage: state.adminState.previewMessage,
		publishState: state.adminState.crdt.publishState,
		publishedAt: state.adminState.crdt.publishedAt,
		currentViewport: state.adminState.viewport,
		idling: state.adminState.idling,
		CRDTSynced: state.adminState.CRDTSynced,
		adminLockedSpinner: state.adminState.adminLockedSpinner,
		site_url: state.site.site_url,
		domain_active: state.site.domain_active,
		domain_pending: state.site.domain_pending,
		domain: state.site.domain,
		auth: state.auth,
		website_title: state.site.website_title,
		favicon_url: state.site.favicon_url,
		hasFontCollection: state.fontCollection.hasFontCollection,
		hasAdminList: state.adminState.hasAdminList,
		userMeta: state.user?.meta,
		user: state.user,
		editorRole: editorRole,
		viewport: state.adminState.viewport,
		publishingAnimationActive: state.adminState.publishingAnimationActive,
		expirationDate: state.site.upgrade_expire_date,
		is_upgraded: state.site.is_upgraded,
		promoCodeTransition: state.adminState.promoCodeTransition,
		shopId: state.site.shop_id,
		commerceWindowOpen: state.uiWindows.byId?.['commerce-window'] ? true : false,
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		undo: actions.undo,
		redo: actions.redo,
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow,
		fetchFontCollection: actions.fetchFontCollection,
		fetchCustomFontCollection: actions.fetchCustomFontCollection,
		fetchUser: actions.fetchUser,
		fetchSiteModel: actions.fetchSiteModel,
		fetchSiteDesignModel: actions.fetchSiteDesignModel,
		fetchFiles: actions.fetchFiles,
		fetchAdminList: actions.fetchAdminList,
		updateAdminState: actions.updateAdminState,
		updateUserMeta: actions.updateUserMeta,
		// Temporary until we have a proper subscriptions/addons included in the user model
		fetchSubscriptions: actions.fetchSubscriptions,
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(App))